var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{staticClass:"rounded-0 text-white",attrs:{"color":"primary","height":"80px"}},[_c('v-toolbar-title',{staticClass:"ml-3"},[_c('h2',[_c('b',[_vm._v("Tanımlar")]),_vm._v(" - Ürün Listesi")])]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-items-center"},[_c('v-icon',{directives:[{name:"permission",rawName:"v-permission",value:({
          permissions: ['productions-delete'],
        }),expression:"{\n          permissions: ['productions-delete'],\n        }"}],staticClass:"mr-2",attrs:{"color":"error cursor-pointer","dark":"","disabled":!_vm.isSelectable},on:{"click":_vm.showDeletePackageModal}},[_vm._v(" mdi-delete ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cursor-pointer","dark":""},on:{"click":() => _vm.fetchPackages()}},'v-icon',attrs,false),on),[_vm._v(" mdi-reload ")])]}}])},[_c('span',[_vm._v("Yenile")])]),_c('v-text-field',{staticClass:"mx-4 mt-0 pt-0",attrs:{"label":"Ara","hide-details":"auto","single-line":"","dark":"","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('router-link',{attrs:{"to":{ name: 'def-package-edit', query: { editable: true } }}},[_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:({
            permissions: ['productions-create'],
          }),expression:"{\n            permissions: ['productions-create'],\n          }"}],attrs:{"elevation":"0","color":"success"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Yeni Ürün ")],1)],1)],1),_c('KTTopMenu')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.packages,"search":_vm.search,"loading":_vm.loading,"sort-by":"id","sort-desc":"true","show-select":"","items-per-page":100,"single-select":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',{attrs:{"to":{
                name: 'def-package-edit',
                params: { id: item.id },
                query: { editable: true },
              }}},[_c('v-icon',_vm._g(_vm._b({directives:[{name:"permission",rawName:"v-permission",value:({
                  permissions: ['productions-update'],
                }),expression:"{\n                  permissions: ['productions-update'],\n                }"}],attrs:{"color":"warning","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Düzenle")])])]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{directives:[{name:"permission",rawName:"v-permission",value:({
            permissions: ['productions-read'],
          }),expression:"{\n            permissions: ['productions-read'],\n          }"}],attrs:{"to":{
            name: 'def-package-edit',
            params: { id: item.id },
            query: { editable: false },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.discounted_price",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatPrice(item.discounted_price))+" ")])]}},{key:"item.is_active",fn:function({ item }){return [(item.is_active)?_c('div',[_c('i',{staticClass:"las la-check",staticStyle:{"color":"green"}})]):_c('div',[_c('i',{staticClass:"las la-times",staticStyle:{"color":"red"}})])]}}]),model:{value:(_vm.selectedPackage),callback:function ($$v) {_vm.selectedPackage=$$v},expression:"selectedPackage"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }