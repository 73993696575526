<template>
  <ul class="menu-nav">
    <!-- <v-select
      dense
      label="Rol Seçici"
      @change="handleRoleChange"
      :items="roles"
      hide-details="auto"
      style="width: 24rem"
    /> -->
  </ul>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CURRENT_ROLE } from "../../../core/services/store/roles.module";
export default {
  name: "KTMenu",
  methods: {
    ...mapActions([CURRENT_ROLE]),
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    handleRoleChange(role) {
      this.$store.dispatch(CURRENT_ROLE, {
        role,
        router: this.$router,
      });
    },
  },
  computed: {
    ...mapGetters(["roles"]),
  },
};
</script>
