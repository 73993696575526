<template>
  <v-card>
    <v-toolbar color="primary" class="rounded-0 text-white" height="80px">
      <v-toolbar-title class="ml-3">
        <h2><b>Tanımlar</b> - Ürün Listesi</h2>
      </v-toolbar-title>
      <v-spacer />
      <div class="d-flex align-items-center">
        <v-icon
          color="error cursor-pointer"
          class="mr-2"
          v-permission="{
            permissions: ['productions-delete'],
          }"
          dark
          :disabled="!isSelectable"
          @click="showDeletePackageModal"
        >
          mdi-delete
        </v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="cursor-pointer"
              v-bind="attrs"
              dark
              @click="() => fetchPackages()"
              v-on="on"
            >
              mdi-reload
            </v-icon>
          </template>
          <span>Yenile</span>
        </v-tooltip>
        <v-text-field
          v-model="search"
          label="Ara"
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          single-line
          dark
          append-icon="search"
        ></v-text-field>
        <router-link
          :to="{ name: 'def-package-edit', query: { editable: true } }"
        >
          <v-btn
            v-permission="{
              permissions: ['productions-create'],
            }"
            elevation="0"
            color="success"
          >
            <v-icon small>mdi-plus</v-icon>
            Yeni Ürün
          </v-btn>
        </router-link>
      </div>
      <KTTopMenu></KTTopMenu>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        v-model="selectedPackage"
        :headers="headers"
        :items="packages"
        :search="search"
        :loading="loading"
        sort-by="id"
        sort-desc="true"
        show-select
        :items-per-page="100"
        single-select
        item-key="id"
      >
        <template #item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link
                :to="{
                  name: 'def-package-edit',
                  params: { id: item.id },
                  query: { editable: true },
                }"
              >
                <v-icon
                  color="warning"
                  small
                  v-permission="{
                    permissions: ['productions-update'],
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
            <span>Düzenle</span>
          </v-tooltip>
        </template>
        <template #item.name="{ item }">
          <router-link
            v-permission="{
              permissions: ['productions-read'],
            }"
            :to="{
              name: 'def-package-edit',
              params: { id: item.id },
              query: { editable: false },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template #item.discounted_price="{ item }">
          <span>
            {{ formatPrice(item.discounted_price) }}
          </span>
        </template>
        <template #item.is_active="{ item }">
          <div v-if="item.is_active">
            <i class="las la-check" style="color: green"></i>
          </div>
          <div v-else>
            <i class="las la-times" style="color: red"></i>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  FETCH_PACKAGES,
  DELETE_PACKAGE,
} from "@/core/services/store/definitions/packages.module";

import KTTopMenu from "@/view/layout/header/TopMenu.vue";
import { formatPrice } from "@/core/helper";

export default {
  name: "PackageList",
  components: { KTTopMenu },
  data() {
    return {
      search: "",
      headers: [
        { text: "Ürün Kodu", value: "name", align: "left" },
        { text: "Ürün İsmi", value: "display_name", align: "left" },
        { text: "Ürün Fiyatı", value: "discounted_price", align: "left" },
        { text: "Aktif", value: "is_active", align: "left" },
        {
          text: "",
          value: "actions",
          align: "left",
          sortable: false,
        },
        { text: "", value: "data-table-select", align: "right" },
      ],
      selectedPackage: [],
      loading: true,
    };
  },
  mounted() {
    this.fetchPackages().then(() => (this.loading = false));
  },
  methods: {
    formatPrice,
    ...mapActions([FETCH_PACKAGES, DELETE_PACKAGE]),
    async acceptDelete() {
      try {
        await this.deletePackage(this.selectedPackage[0]);
        this.$toast.success("Ürün silindi", {
          position: "bottom-right",
          duration: 2000,
        });
        this.selectedPackage = [];
      } catch (error) {
        this.$toast.error("Ürün silinirken bir hata oluştu", {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    showDeletePackageModal() {
      const params = {
        title: "Seçtiğiniz ürün silinecek",
        text: "Seçili ürünü silmek istediğinize emin misiniz?",
        onConfirm: () => {
          return this.acceptDelete();
        },
      };
      this.$approvalModal.show(params);
    },
  },
  computed: {
    ...mapGetters(["packages"]),
    isSelectable() {
      return this.selectedPackage.length > 0;
    },
  },
};
</script>

<style scoped></style>
